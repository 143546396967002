import React, { useEffect } from 'react'
import progressService from 'Services/progressService'
import dynamic from 'next/dynamic'

const DefaultLoading = () => {
  useEffect(() => {
    progressService.load()
    return () => {
      progressService.done()
    }
  }, [])

  return <div />
}

const Lazyload = (importFunc, loading = DefaultLoading, ssr = false) =>
  dynamic(importFunc, {
    ssr,
    loading,
  })

export default Lazyload
